import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo"
import CTA from '../components/cta';

import BlogFeed from '../components/blog-feed';

import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../components/quote";
import RecentMigrations from '../components/recent-migrations';

const tools = [
  <StaticImage
    src="../images/tools/salesforce.png"
    className="m-auto"
    alt="salesforce logo"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/tools/freshdesk.png"
    className="m-auto"
    alt="freshdesk logo"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/tools/happyfox.png"
    className="m-auto"
    alt="happyfox logo"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/tools/helpscout.png"
    className="m-auto"
    alt="helpscout logo"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/tools/Kustomer.png"
    className="m-auto"
    alt="Kustomer logo"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/tools/desk.png"
    className="m-auto"
    alt="Desk logo"
    placeholder="blurred"
  />
];

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Migrations"
      description="729 Solutions is Master of Zendesk Partner and can help you migrate from your old service. Contact us for a smooth transition!"
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">
            729 Solutions Is Here For All Of Your Zendesk Needs
          </h1>
          <p className="text-white">
            729 can help you migrate all of your customer data from your old tool into Zendesk and set you up for
            success. We will make sure you safely move all of your precious customer records including companies,
            conversations, users, account info, etc.</p>
          <CTA href="/contact-us/" className="btn btn-primary" text="Tell Us About Your Migration"/>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-6">
            <h2>migration types we can do</h2>
            <ul className="text-primary m-0 pl-3">
              <li><span className="text-dark">Migration and mapping from Data files provided (csv, excel, etc.) API > API migration</span>
              </li>
              <li><span className="text-dark">Closed / Homegrown systems > Zendesk (even build API end points if needed!)</span>
              </li>
              <li><span className="text-dark">Article or knowledge content migration to a new help center (HTML, CSS, Scraping, or real time API Integration)</span>
              </li>
              <li><span className="text-dark">Build custom migration tools for ongoing real time syncing of data</span>
              </li>
              <li><span className="text-dark">Zendesk > Zendesk Migration (multibrand / hub and spoke…)</span></li>
            </ul>
          </div>
          <div className="col-lg-6">
            <h2>tools we can migrate</h2>
            {tools.map((component) => (
              <div style={{maxWidth: '30%'}} className="ml-auto mr-auto mb-4">
                {component}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col">
            <h2>recent migrations customers</h2>
          </div>
        </div>
        <RecentMigrations/>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col">
            <Quote
              name="Deeanne Akerson"
              company="Kindred Bravely"
              quote="We spent several months trying to sort out our Zendesk workflow on our own with dozens of calls with Zendesk’s internal help team. After one call with 729 Solutions, they understood our unique workflow and what we were trying to do. every eCommerce business needs them on their team!"
            />
          </div>
        </div>
      </div>
    </section>
    <NeedAQuote dark/>
    <BlogFeed containerClassname="py-6"/>
  </Layout>
);

export default Index;
