import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const migrations = [
  <StaticImage
    src="../images/clients/zendesk-customers/learnondemand.png"
    className="m-auto"
    alt="Learnondemand"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/genomix.png"
    className="m-auto"
    alt="Genomix"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/asg.png"
    className="m-auto"
    alt="Asg"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/pureflix.png"
    className="m-auto"
    alt="Pureflix"
    placeholder="blurred"
  />,
];

const RecentMigrations = () => (
  <div className="row">
    {migrations.map((component) => (
      <div className="col-12 col-lg-3">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row">
              <div className="col-4 col-lg-12 ml-auto mr-auto mb-5">
                {component}
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default RecentMigrations;
